import { useEffect, useState } from "react";

import PollarisApi from "../services/PollarisApi";

import ToastService from "../services/ToastService";

import { useForm } from 'react-hook-form';

const PageMeusDados = (props) => {
  
  document.title = "Área do Cliente | Meus Dados";

  const [customer, setCustomer] = useState({});

  const toastService = new ToastService();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {

    toastService.info("Aguarde..", "Salvando...");

    var currentCustomer = { ...customer };

    const newCustomer = { ...currentCustomer, ...data };

    console.table(newCustomer);

    const apiPollaris = new PollarisApi();

    apiPollaris
      .put(`clientes/${currentCustomer.id}`, newCustomer)
      .then((response) => {
        toastService.success("Sucesso..", "Dados salvos com sucesso...");
      })
      .catch((error) => {

        console.error(error);

        toastService.error("Erro ao salvar dados", error.message);

      });

  }

  useEffect(() => {

    const loadDados = () => {

      const auth = JSON.parse(localStorage.getItem("authentication"));

      const apiPollaris = new PollarisApi();

      apiPollaris
        .get(`clientes/${auth.customer.id}`)
        .then((response) => {
          const loadedCustomer = JSON.parse(response);
          setCustomer(loadedCustomer);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    loadDados();
  }, []);

  return (
    customer && customer.id ? (

      <div className="home">
        <div className="container">
          <div>
            <h4>Meus Dados</h4>
          </div>
          <div className="card shadow">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control " {...register('nome', { value: customer.nome, required: true })} />
                      <label>Nome / Razao Social</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control " {...register('fantasia', { value: customer.fantasia, required: false })} />
                      <label>Apelido / Nome Fantasia</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" {...register('cnpj', { value: customer.cnpj, required: true })} />
                      <label>CPF / CNPJ</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" {...register('ie', { value: customer.ie, required: false })} />
                      <label>RG / IE</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input type="datetime-local" className="form-control" {...register('nascimento', { value: customer.nascimento, required: true })} />
                      <label>Nascimento</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input type="email" className="form-control " {...register('email', { value: customer.email, required: true })} />
                      <label>Email</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input type="phone" className="form-control " {...register('telefone', { value: customer.telefone, required: true })} />
                      <label>Telefone</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control " {...register('endereco', { value: customer.endereco, required: true })} />
                      <label>Endereço</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control " {...register('numero', { value: customer.numero, required: false })} />
                      <label>Número</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control " {...register('cidade', { value: customer.cidade, required: true })} />
                      <label>Cidade</label>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-floating mb-3">
                      <select type="text" className="form-control " {...register('estado', { value: customer.estado, required: true })} >
                        <option value="">Selecione</option>
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AP">AP</option>
                        <option value="AM">AM</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="DF">DF</option>
                        <option value="ES">ES</option>
                        <option value="GO">GO</option>
                        <option value="MA">MA</option>
                        <option value="MT">MT</option>
                        <option value="MS">MS</option>
                        <option value="MG">MG</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PR">PR</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="RJ">RJ</option>
                        <option value="RN">RN</option>
                        <option value="RS">RS</option>
                        <option value="RO">RO</option>
                        <option value="RR">RR</option>
                        <option value="SC">SC</option>
                        <option value="SP">SP</option>
                        <option value="SE">SE</option>
                        <option value="TO">TO</option>
                      </select>
                      <label>Estado</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control " {...register('bairro', { value: customer.bairro, required: true })} />
                      <label>Bairro</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control " {...register('cep', { value: customer.cep, required: true })} />
                      <label>CEP</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <button type="submit" className="btn form-control col-12 btn-success-pollaris btn-lg" >
                        <i class="bi bi-check"></i> Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    ) : false
  );
};

export default PageMeusDados;