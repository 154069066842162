import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import PollarisApi from "../services/PollarisApi";

const PageHome = () => {

  document.title = "Área do Cliente - Início";

  let loaded = false;

  const [customerLicence, setCustomerLicence] = useState({});

  useEffect(() => {

    if (loaded) return;

    loaded = true;

    const loadLicence = () => {

      const apiPollaris = new PollarisApi();

      var auth = JSON.parse(localStorage.getItem("authentication"));

      apiPollaris
        .get(`clientes/${auth.customer.id}/dados`)
        .then((response) => {
          const loadedCustomerLicence = JSON.parse(response);
          setCustomerLicence(loadedCustomerLicence);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    loadLicence();
  }, []);

  return (
    <div className="container">
      <div className="col-12">
        <div>
          <h4>Meus Contratos</h4>
        </div>
      </div>
      {customerLicence && customerLicence.contratos
        ? customerLicence.contratos.filter(n => n.contrato.ativo).map((contrato) => (
          <div className="mb-3 border col-12 shadow" key={contrato.contrato.id}>
            <div className="p-4">
              <div className="row">
                <div className="col-6">
                  <small className="text-secondary">
                    {contrato.programa}
                  </small>
                  <h4>{contrato.descricao}
                  </h4>
                  <span className="badge rounded-pill text-bg-secondary">Dias restantes: {contrato.diasRestantes}</span>&nbsp;
                  {contrato.vencido ? <span className="badge rounded-pill text-bg-danger">Vencido</span> : false}
                </div>
                <div className="col-6 text-secondary">
                  <h4 className="float-end">
                    <Link to={`/pagar/${contrato.contrato.id}`} className="btn btn-lg btn-outline-dark">
                      <i className="bi bi-credit-card-fill"></i> Pagar R${contrato.valor.toFixed(2)}
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        ))
        : null}
    </div>
  );
};

export default PageHome;