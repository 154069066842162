import { useEffect, useState } from "react";

import moment from 'moment';

import { useNavigate } from "react-router-dom";

import PollarisApi from "../services/PollarisApi";
import Placeholder from "../components/Placeholder";

const PagePagamento = () => {

  document.title = "Área do Cliente | Meus Pagamentos";

  let loaded = false;

  const navigate = useNavigate();

  const [payments, setPayments] = useState([]);

  useEffect(() => {

    if (loaded) return;

    loaded = true;

    const loadPayments = () => {

      const auth = JSON.parse(localStorage.getItem("authentication"));

      const apiPollaris = new PollarisApi();

      apiPollaris
        .get(`clientes/${auth.customer.id}/cobrancas`)
        .then((response) => {

          const loadedPayment = JSON.parse(response);

          setPayments(loadedPayment);

        })
        .catch((error) => {
          console.log(error);
        });
    };

    loadPayments();

  }, []);

  return (
    payments.length === 0 ? <Placeholder /> :
      <div className="container">
        <div>
          <h4>Meus Pagamentos</h4>
        </div>
        <div className="card shadow">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>codigo</th>
                    <th>Data</th>
                    <th>Valor</th>
                    <th>Forma</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(payments).map((key) => {
                    const payment = payments[key];

                    return (
                      <tr key={payment.id} onClick={() => { navigate(`/pagamento/${payment.codigo}`) }} style={{ cursor: "hand" }}>
                        <td>{payment.id}</td>
                        <td>{moment(payment.datahora).format("DD/MM/YYYY HH:MM:ss")}</td>
                        <td>R${parseFloat(payment.valor).toFixed(2)}</td>
                        <td>{payment.pagamento}</td>
                        <td>
                          {payment.datapago ?
                            <span className="badge bg-success">Autorizado</span>
                            : payment.dataestorno ?
                              <span className="badge bg-danger">Cancelado</span>
                              : <span className="badge bg-warning">Aguardando</span>
                          }
                        </td>
                      </tr>
                    );
                  }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
};

export default PagePagamento;