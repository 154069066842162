import { useState } from "react";

import { useNavigate } from "react-router-dom";

import PollarisApi from "../../services/PollarisApi";

import CreditCardForm from "../CreditCardForm/CreditCardForm";

import ToastService from "../../services/ToastService";

export default ({ contractId, typeOfCard }) => {

    const apiPollaris = new PollarisApi();

    const navigate = useNavigate();

    const toastService = new ToastService();

    const [loading, setLoading] = useState(false);

    // const responseFake = `{"success":true,"body":{"numero":"510510******5100","bandeira":"0","parcelas":"1","transactionId":"34465655","success":true,"message":"Pagamento Autorizado","descricao":"O seu pagamento foi autorizado pela operadora do cartão de crédito."}}`
    // onCompleted(<CreditCardCallBack response={JSON.parse(responseFake)} />);

    const submitForm = (cardData) => {

        setLoading(true);

        apiPollaris.post(`cobrancas/${typeOfCard.method}/${contractId}`, cardData).then(response => {

            setLoading(false);

            const responseObj = JSON.parse(response);

            console.log(response);

            navigate(`/pagamento/${responseObj.reference}`);
        }
        ).catch(error => {

            setLoading(false);

            console.error(error);

            toastService.error("Erro ao processar pagamento", error.message);
        }
        );

    }

    return (
        <div className="cardPayLoad">
            {loading ?
                <div className="text-center text-secondary">
                    <h4>Aguarde...</h4>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                : <CreditCardForm onSubmit={submitForm} typeOfCard={typeOfCard} />}
        </div >
    )
}