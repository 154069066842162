
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"

import { useState } from "react";

import Navbar from "./components/Navbar";

import PageHome from "./pages/PageHome";
import PageLogin from "./pages/PageLogin";
import PageMeusDados from "./pages/PageMeusDados";
import PagePagamento from "./pages/PagePagamento";
import PagePagamentos from "./pages/PagePagamentos";
import PagePagar from "./pages/PagePagar";
import PageRecuperarSenha from "./pages/PageRecuperarSenha";

function RoutesApp() {

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

    useState(() => {

        const authJson = localStorage.getItem("authentication");

        if (authJson) {

            var auth = JSON.parse(authJson);

            const expired = new Date(auth.expire);

            const now = new Date();

            if (now > expired) {

                localStorage.removeItem("authentication");

                setIsUserAuthenticated(false);

            } else {

                setIsUserAuthenticated(true);

            }
        }

    });



    return (
        <BrowserRouter>

            {isUserAuthenticated ? (
                <div>
                    <Navbar />
                    <Routes>
                        <Route path="/login" element={<Navigate replace to="/" />} />
                        <Route exact path="/" element={<PageHome />} />
                        <Route exact path="/meus-dados" element={<PageMeusDados />} />
                        <Route exact path="/pagar/:contractId" element={<PagePagar />} />
                        <Route exact path="/pagamento/:paymentId" element={<PagePagamento />} />
                        <Route exact path="/meus-pagamentos" element={<PagePagamentos />} />
                    </Routes>
                </div>
            ) : (
                <Routes>
                    <Route path="*" element={<Navigate replace to="/login" />} />
                    <Route exact path="/recuperar" element={<PageRecuperarSenha />} />
                    <Route exact path="/recuperar/:recoveryCodeParam" element={<PageRecuperarSenha />} />
                    <Route exact path="/login" element={<PageLogin />} />
                </Routes>
            )}
        </BrowserRouter >
    )
}

export default RoutesApp;