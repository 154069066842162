import "./App.css";

import React from "react";

import RoutesApp from "./components/routes";

function App() {

  document.title = "Pollaris - Área do Cliente";

  return (
    <div className="App">
      <RoutesApp />
    </div>
  );
}

export default App;
