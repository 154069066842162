import { tata, success, error, info, warn } from "tata-js";

export default class ToastService {

  constructor() {
    this.position = { position: "bl" };
  }

  success(title, message) {
    success(title, message);
  }

  error(title, message) {
    error(title, message);
  }

  warning(title, message) {
    warn(title, message);
  }

  info(title, message) {
    info(title, message);
  }
}
