import { useState } from "react";
import { Link } from "react-router-dom";

const NavBar = () => {

  const [user, setUser] = useState("");

  useState(() => {

    const authJson = localStorage.getItem("authentication");

    if (authJson) {

      var auth = JSON.parse(authJson);

      setUser(auth.customer);
    }

  });

  const logout = () => {

    localStorage.removeItem("authentication");

    window.location.href = "/login";

  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark mb-4 shadow bg-pollaris">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">{user?.nome?.split(' ')[0]}</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/meus-dados">Meus Dados</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/meus-pagamentos">Pagamentos</Link>
            </li>
          </ul>
          <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="#" onClick={() => logout()}>Sair</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;