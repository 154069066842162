import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import PixPayLoad from "../components/PaymentPayLoads/PixPayLoad";

import BoletoPayLoad from "../components/PaymentPayLoads/BoletoPayLoad";

import PollarisApi from "../services/PollarisApi";

import ToastService from "../services/ToastService";

import CreditCardPayLoad from "../components/PaymentPayLoads/CreditCardPayLoad";

const PagePagar = (props) => {

  document.title = "Área do Cliente | Pagar";

  var loaded = false;

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [contract, setContract] = useState(null);

  const [callBackElement, setCallBackElement] = useState(null);

  const [paymentMethod, setPaymentMethod] = useState(null);

  const toastService = new ToastService();

  const { contractId } = useParams();

  const typeOfCardCredit = {
    method: "credito",
    name: "Cartão de Crédito",
    icon: "bi-credit-card",
  }

  const typeOfCardDebit = {
    method: "debito",
    name: "Cartão de Débito",
    icon: "bi-credit-card-2-back",
  }


  const paymentMethodCreditCard = { element: <CreditCardPayLoad contractId={contractId} typeOfCard={typeOfCardCredit} />, name: "Cartão de Crédito", icon: "bi-credit-card-fill" };

  const paymentMethodDebitCard = { element: <CreditCardPayLoad contractId={contractId} typeOfCard={typeOfCardDebit} />, name: "Cartão de Débito", icon: "bi-credit-card-2-back" };

  const paymentMethodPix = { element: <PixPayLoad contractId={contractId} />, name: "Pix", icon: "bi-qr-code" };

  const paymentMethodBoleto = { element: <BoletoPayLoad contractId={contractId} />, name: "Boleto", icon: "bi-upc" };

  const selectPaymentMethod = (newPaymentMethod) => {

    if (paymentMethod && (paymentMethod.name == newPaymentMethod.name)) {
      setPaymentMethod(null);
    } else {
      setPaymentMethod(newPaymentMethod);
    }

  }

  const onSubmit = (data) => {

    toastService.info("Aguarde..", "Salvando...");

    const apiPollaris = new PollarisApi();
  }

  useEffect(() => {

    if (loaded) return;

    loaded = true;

    loadDados();
  }, []);

  const loadDados = () => {

    const auth = JSON.parse(localStorage.getItem("authentication"));

    const apiPollaris = new PollarisApi();

    apiPollaris
      .get(`contratos/${contractId}`)
      .then((response) => {
        const loadedContract = JSON.parse(response);
        setContract(loadedContract);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    contract && contract.contrato.id ? (
      <div className="container">
        <h4>Pagar</h4>
        <div className="card shadow">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="card">
                  <div className="card-body">
                    <div className="mb-3">
                      <small className="text-secondary">{capitalizeFirstLetter(contract.produto.programa)}</small>
                      <h5>{contract.produto.nome}</h5>
                      <h1 className="text-dark"><strong>R${contract.contrato.valor.toFixed(2)}</strong></h1>
                      <span className="badge rounded-pill text-bg-secondary">Dias restantes: {contract.diasRestantes}</span>&nbsp;
                      {contract.vencido ? <span className="badge rounded-pill text-bg-danger">Vencido</span> : false}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {callBackElement ? callBackElement :
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button onClick={() => selectPaymentMethod(paymentMethodPix)} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePix" aria-expanded="true" aria-controls="collapseOne">
                          <i className="bi bi-qr-code"></i>&nbsp;PIX
                        </button>
                      </h2>
                      <div id="collapsePix" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          {paymentMethodPix.element}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button onClick={() => selectPaymentMethod(paymentMethodBoleto)} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBoleto" aria-expanded="true" aria-controls="collapseOne">
                          <i className="bi bi-upc"></i>&nbsp;Boleto
                        </button>
                      </h2>
                      <div id="collapseBoleto" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          {paymentMethodBoleto.element}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button onClick={() => selectPaymentMethod(paymentMethodCreditCard)} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCartaoCredito" aria-expanded="false" aria-controls="collapseTwo">
                          <i className="bi bi-credit-card"></i>&nbsp;Cartão de Crédito
                        </button>
                      </h2>
                      <div id="collapseCartaoCredito" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          {paymentMethodCreditCard.element}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button onClick={() => selectPaymentMethod(paymentMethodDebitCard)} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCartaoDebito" aria-expanded="false" aria-controls="collapseThree">
                          <i className="bi bi-credit-card-2-back"></i>&nbsp;Cartão de Débito
                        </button>
                      </h2>
                      <div id="collapseCartaoDebito" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          {paymentMethodDebitCard.element}
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div >
    ) : false
  );
};

export default PagePagar;